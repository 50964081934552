import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import LoadingSpinner from './components/LoadingSpinner';

const Bills = ({ apiKey }) => {
  const [bills, setBills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [offset, setOffset] = useState(0);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const navigate = useNavigate();
  const [clickLoading, setClickLoading] = useState(false);

  useEffect(() => {
    const fetchBills = async () => {
      try {
        const response = await axios.get(
          `https://api.congress.gov/v3/bill?format=json&offset=${offset}&limit=10&sort=updateDate&api_key=${apiKey}`
        );
        setBills((prevBills) => [...prevBills, ...response.data.bills]);
        if (!initialLoadComplete) {
          setInitialLoadComplete(true);
        }
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    fetchBills();
  }, [apiKey, offset, initialLoadComplete]);

  useEffect(() => {
    if (initialLoadComplete) {
      setOffset(prevOffset => prevOffset + 20);
    }
  }, [initialLoadComplete]);

  const handleScroll = () => {
    // Get total height of the document
    const totalHeight = document.documentElement.offsetHeight;
    // Get current scroll position
    const currentScroll = window.innerHeight + document.documentElement.scrollTop;
    // Calculate halfway point
    const halfwayPoint = totalHeight / 2;

    if (currentScroll >= halfwayPoint) {
      setOffset((prevOffset) => prevOffset + 20);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleBillClick = (bill) => {
    setClickLoading(true);
    navigate(`/bills/${bill.congress}/${bill.type}/${bill.number}`);
  };

  if (loading && bills.length === 0) return <LoadingSpinner />;
  if (error) return <div>Error fetching data: {error.message}</div>;

  return (
    <div className="bills-content">
      {clickLoading ? (
        <LoadingSpinner />
      ) : (
        bills.map((bill) => (
          <div
            key={`${bill.congress}-${bill.type}-${bill.number}`}
            className="standard-bill"
            onClick={() => handleBillClick(bill)}
          >
            <h2>{bill.title}</h2>
            <p>
              {bill.type}.{bill.number} - {bill.latestAction.text}
            </p>
          </div>
        ))
      )}
    </div>
  );
};

export default Bills;
