import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import LoadingSpinner from './components/LoadingSpinner';

const Members = ({ apiKey }) => {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [clickLoading, setClickLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [displayLimit, setDisplayLimit] = useState(20);
  const navigate = useNavigate();

  const filteredMembers = useMemo(() => {
    if (!searchTerm) return []; // Return empty array if no search term
    
    return members.filter(member => {
      const matchName = member.name.toLowerCase().includes(searchTerm.toLowerCase());
      const matchState = member.state.toLowerCase().includes(searchTerm.toLowerCase());
      const matchParty = member.partyName.toLowerCase().includes(searchTerm.toLowerCase());
      return matchName || matchState || matchParty;
    }).slice(0, displayLimit);
  }, [members, searchTerm, displayLimit]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop
        === document.documentElement.offsetHeight
      ) {
        setDisplayLimit(prev => prev + 20);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setDisplayLimit(20);
  }, [searchTerm]);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        // First page
        const firstResponse = await axios.get(
          `https://api.congress.gov/v3/member/congress/118?format=json&offset=0&limit=250&api_key=${apiKey}`
        );
        
        let allMembers = [...firstResponse.data.members];
        
        // If there are more members, get the next page
        if (firstResponse.data.members.length === 250) {
          const secondResponse = await axios.get(
            `https://api.congress.gov/v3/member/congress/118?format=json&offset=250&limit=250&api_key=${apiKey}`
          );
          allMembers = [...allMembers, ...secondResponse.data.members];
        }
        
        // Deduplicate members
        const uniqueMembers = new Map();
        allMembers.forEach(member => {
          uniqueMembers.set(member.bioguideId, member);
        });
        
        setMembers(Array.from(uniqueMembers.values()));
        
      } catch (error) {
        setError(error);
        console.error('Error fetching members:', error);
      }
      setLoading(false);
    };

    fetchMembers();
  }, [apiKey]);

  const handleMemberClick = (bioguideId) => {
    setClickLoading(true);
    navigate(`/member/${bioguideId}`);
  };

  if (loading && members.length === 0) return <LoadingSpinner />;
  if (error) return <div>Error fetching data: {error.message}</div>;

  return (
    <div className="members-content">
      <div className="members-controls">
        <input
          type="text"
          placeholder="Search current members by name, state, or party..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
      </div>
      
      {searchTerm && (
        <div style={{color: 'white', marginBottom: '10px'}}>
          {filteredMembers.length === 0 
            ? 'No current members found' 
            : `Showing ${filteredMembers.length} of ${members.filter(member => {
                const matchName = member.name.toLowerCase().includes(searchTerm.toLowerCase());
                const matchState = member.state.toLowerCase().includes(searchTerm.toLowerCase());
                const matchParty = member.partyName.toLowerCase().includes(searchTerm.toLowerCase());
                return matchName || matchState || matchParty;
              }).length} matching members`
          }
        </div>
      )}
      
      {clickLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {filteredMembers.map((member) => (
            <div
              key={member.bioguideId}
              className="standard-member"
              onClick={() => handleMemberClick(member.bioguideId)}
            >
              <div className="member-info">
                <h2>{member.name}</h2>
                <p>
                  {member.terms.item[0].chamber} - {member.state}
                  {member.district && ` (District ${member.district})`}
                </p>
                <p className="member-party">{member.partyName}</p>
              </div>
              {member.depiction?.imageUrl && (
                <img
                  src={member.depiction.imageUrl}
                  alt={member.name}
                  className="member-image"
                  onError={(e) => {
                    e.target.style.display = 'none';
                  }}
                />
              )}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

const styles = `
.members-controls {
  margin-bottom: 20px;
  padding: 10px;
}

.search-input {
  width: 100%;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: var(--background-light);
  color: var(--text-primary);
  border: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 16px;
}

.search-input:focus {
  outline: none;
  border-color: var(--accent-blue);
}

.search-input::placeholder {
  color: var(--text-secondary);
}
`;

const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default Members;
