import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import './Laws.css';
import LoadingSpinner from './components/LoadingSpinner';

const Laws = () => {
  const [laws, setLaws] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [clickLoading, setClickLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const { congress } = useParams();
  const navigate = useNavigate();
  const apiKey = 'NHdJjzbcGyGWRWiqihZ5SH6FZdChkW2fMVOBh5lT'; 

  useEffect(() => {
    const fetchLaws = async () => {
      try {
        const response = await axios.get(`https://api.congress.gov/v3/law/${congress}`, {
          params: {
            api_key: apiKey,
            offset: offset,
            limit: 10,
            sort: 'updateDate'
          }
        });
        setLaws(prevLaws => [...prevLaws, ...(response.data.bills || [])]);
        if (!initialLoadComplete) {
          setInitialLoadComplete(true);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching laws:', error);
        setError('Failed to fetch laws. Please try again later.');
        setLoading(false);
      }
    };

    fetchLaws();
  }, [congress, apiKey, offset, initialLoadComplete]);

  // New useEffect to pre-load more laws after initial load
  useEffect(() => {
    if (initialLoadComplete) {
      setOffset(prevOffset => prevOffset + 10);
    }
  }, [initialLoadComplete]);

  const handleScroll = () => {
    const totalHeight = document.documentElement.offsetHeight;
    const currentScroll = window.innerHeight + document.documentElement.scrollTop;
    const halfwayPoint = totalHeight / 2;

    if (currentScroll >= halfwayPoint) {
      setOffset(prevOffset => prevOffset + 10);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleLawClick = async (law) => {
    setClickLoading(true);
    navigate(`/bills/${congress}/${law.type}/${law.number}`);
  };

  if (loading && laws.length === 0) return <LoadingSpinner />;
  if (error) return <div>{error}</div>;

  return (
    <div className="laws-container">
      {clickLoading ? (
        <LoadingSpinner />
      ) : (
        <ul className="laws-list">
          {laws.map((law, index) => (
            <li 
              key={`${law.type}-${law.number}-${index}`}
              className="law-item" 
              onClick={() => handleLawClick(law)}
            >
              <h2>{law.title}</h2>
              <p>Bill: {law.type} {law.number}</p>
              <p>Origin: {law.originChamber}</p>
              <p>Latest Action: {law.latestAction.text} ({law.latestAction.actionDate})</p>
              {law.laws && law.laws.length > 0 && (
                <p>Public Law: {law.laws[0].type} {law.laws[0].number}</p>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Laws;